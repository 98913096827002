import { render, staticRenderFns } from "./f2g-dialog.vue?vue&type=template&id=2e89b598"
import script from "./f2g-dialog.vue?vue&type=script&lang=js"
export * from "./f2g-dialog.vue?vue&type=script&lang=js"
import style0 from "./f2g-dialog.vue?vue&type=style&index=0&id=2e89b598&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
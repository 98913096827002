<template>
  <div class="f2gDialog">
    <v-dialog
      content-class="dialog-rounded f2gVDialog"
      :value="show"
      :persistent="true"
      :width="width"
      :fullscreen="fullscreen"
      @keydown.esc="$emit('close')"
    >
      <F2gCard
        v-if="show"
        class="cardDialog pb-4"
        :padding="padding"
        noShadow
        style="border: none !important"
      >
        <div
          v-if="showCloseCorner"
          class="closeButtonCorner"
          style="z-index: 1"
        >
          <v-layout justify-end>
            <v-btn icon :elevation="2" style="background-color: #d7d7d7">
              <F2gIcon
                class="cursor-pointer"
                value="close"
                :strokeWidth="4"
                :width="28"
                :height="28"
                @click="$emit('close')"
              />
            </v-btn>
          </v-layout>
        </div>
        <v-layout align-center class="mb-4 nowrap" v-if="showTitle">
          <v-flex v-if="!title" grow>
            <slot name="title"></slot>
          </v-flex>
          <v-flex v-else>
            <v-layout align-center>
              <slot name="prepend-icon"></slot>
              <F2gIcon
                v-if="prependIcon"
                class="mr-3"
                :value="prependIcon"
                :file="prependIconFile"
                :stroke="'black'"
                :fill="statusColor ? statusColor : 'black'"
                :solid="prependIconFile === 'icons_solid.svg'"
                :width="25"
                :height="25"
              />
              <h2 :style="`color: ${statusColor}`">
                {{ title }}
              </h2>
              <F2gUxInfo
                v-if="showHelpTips"
                class="ml-3 mt-1"
                :message="$t('newExtraCategroyHelpText')"
                :helpCardTopPixels="20"
                :helpCardLeftPixels="0"
                :helpCardWidth="300"
              />
              <slot name="append-icon"></slot>
            </v-layout>
          </v-flex>
          <F2gIcon
            v-if="showClose"
            class="cursor-pointer ml-3"
            value="close"
            :strokeWidth="4"
            :width="28"
            :height="28"
            @click="$emit('close')"
          />
        </v-layout>
        <v-layout align-center class="mb-4 nowrap" v-if="showEditableTitle">
          <v-flex v-if="!title" grow>
            <slot name="title"></slot>
          </v-flex>
          <v-flex v-else>
            <v-layout align-center style="align-items: center">
              <F2gIcon
                v-if="prependIcon"
                class="mr-3"
                :value="prependIcon"
                :file="prependIconFile"
                :stroke="'black'"
                :fill="statusColor ? statusColor : 'black'"
                :solid="prependIconFile === 'icons_solid.svg'"
                :width="25"
                :height="25"
              />
              <v-text-field
                class="text--bold"
                style="font-size: 26px"
                v-model="editedTitle"
                flat
                :solo="!editing"
                dense
                hide-details
                :background-color="
                  hoverTitle ? $CONSTANTS.COLOUR.LIGHT_GRAY_2 : 'white'
                "
                @mouseenter="hoverTitle = true"
                @mouseleave="hoverTitle = false"
                @click="editing = true"
                @blur="sendData(editedTitle)"
              />
            </v-layout>
          </v-flex>
          <F2gIcon
            v-if="showClose"
            class="cursor-pointer ml-3"
            value="close"
            :strokeWidth="4"
            :width="28"
            :height="28"
            @click="$emit('close'), clear()"
          />
        </v-layout>

        <slot name="content"></slot>
        <v-layout>
          <slot name="actions-left"></slot>
          <v-layout justify-end align-center>
            <slot name="actions"></slot>
          </v-layout>
        </v-layout>
      </F2gCard>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "F2gDialog",
  props: {
    height: { default: 800, required: false, type: Number },
    prependIcon: { default: "", required: false, type: String },
    prependIconFile: {
      default: "icons_solid.svg",
      required: false,
      type: String,
    },
    scrollable: { default: false, required: false, type: Boolean },
    show: { default: false, required: true, type: Boolean },
    showClose: { default: true, required: false, type: Boolean },
    showCloseCorner: { default: false, required: false, type: Boolean },
    statusCode: { required: false, type: String },
    title: { default: "", required: false, type: String },
    width: { default: 800, required: false, type: [String, Number] },
    fullscreen: { required: false, type: Boolean, default: false },
    padding: { required: false, type: Boolean, default: true },
    showTitle: { required: false, type: Boolean, default: true },
    showHelpTips: { type: Boolean, required: false, default: false },
    showEditableTitle: { type: Boolean, required: false, default: false },
  },
  data: () => ({
    statusColor: "",
    hoverTitle: false,
    editing: false,
    editedTitle: "",
  }),

  mounted() {
    this.setStatusColor();
    this.editedTitle = this.title;
  },
  watch: {
    title() {
      this.editedTitle = this.title;
    },
    editing() {
      if (this.editing === false) {
        this.sendData(this.editedTitle);
      }
    },
  },
  methods: {
    setStatusColor() {
      switch (this.statusCode) {
        case "warning":
          this.statusColor = "#F59217";
          break;

        case "error":
          this.statusColor = "#d32f2f";
          break;
      }
    },
    sendData(data) {
      if (this.title !== data && this.editing === true) {
        this.$emit("sendData", data);
      }
      this.editing = false;
    },

    clear() {
      setTimeout(() => {
        this.editedTitle = this.title;
      }, 100);
    },
  },
};
</script>

<style lang="scss">
.v-sheet.v-stepper:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
.f2gVDialog {
  overflow: auto;
  box-shadow: none;
  .cardDialog {
    // position: relative;
    .closeButtonCorner {
      top: 10px;
      right: 10px;
      position: absolute;
    }
  }
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $green-2-f2g;
    border-radius: 30px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $green-3-f2g;
  }
}
</style>
